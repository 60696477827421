<template>
  <div class="o-signup">
    <SignupForm></SignupForm>
  </div>
</template>

<script>
import SignupForm from '@/components/SignupForm.vue'

export default {
  name: 'Signup',
  components: {
    SignupForm
  },
}
</script>
