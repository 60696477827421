<template>
  <div class = "c-alpha-signup">
    <h1 class="c-alpha-signup__title-body"><span class = "c-alpha-signup__title-body_blueblack">Create </span>your Sparrow Account</h1>
    <div class = "c-alpha-signup__form-container">
      <div class = "c-alpha-signup__form-group">
        <input class = "c-alpha-signup__input" @input="debouncedEmailCheck" :class = "{'c-alpha-signup__input_error':!validEmail}" placeholder = "email address" v-model="input.email">
        <p class="c-alpha-signup__error" v-if = "!validEmail">Invalid email address</p>
      </div>
      <div class = "c-alpha-signup__form-group">
        <div class = "c-alpha-signup__input-container">
          <input class = "c-alpha-signup__input" @input="debouncedPasswordCheck" :class = "{'c-alpha-signup__input_error':!validPassword}" placeholder = "password" :type = "showPassword?'text':'password'" v-model="input.password">
          <img src="@/assets/images/old-alpha/eye.png" class = "c-alpha-signup__password-show" v-if = "!showPassword" @click = "togglePassword">
          <img src="@/assets/images/old-alpha/eye-slash.png" class = "c-alpha-signup__password-show" v-if = "showPassword" @click = "togglePassword">
        </div>
        <p class="c-alpha-signup__error" v-if = "!validPassword">The password must contain at least eight characters and contain uppercase letters, lowercase letters, numbers, and special characters.</p>
        <p class="c-alpha-signup__error" v-if = "errorMessage">{{errorMessage}}</p>
      </div>
      <div class = "c-alpha-signup__checkbox-container">
        <label for = "checkboxCnf" class = "c-alpha-signup__label">
          <input type = "checkbox" v-model = "isCnfChecked" class = "c-alpha-signup__checkbox" id = "checkboxCnf" disabled>
          <span class="c-alpha-signup__label_extraBold">ConvertNOW</span>
          <span class="c-alpha-signup__checkmark c-alpha-signup__checkmark_disable"></span>
        </label>
        <label for = "checkboxOpt" class = "c-alpha-signup__label">
          <input type = "checkbox" v-model = "isOptChecked" class = "c-alpha-signup__checkbox" id = "checkboxOpt" required>
          Enable <span class="c-alpha-signup__label_extraBold">Sparrow Options Trading Services</span>
          <img src="@/assets/images/alert-cross.svg" class = "c-alpha-signup__label_icon c-windsor__body-title-exclamation">
          <span class="c-alpha-signup__checkmark"></span>
        </label>
        <label for = "checkboxSignup" class = "c-alpha-signup__label">
          <input type = "checkbox" v-model = "isChecked" class = "c-alpha-signup__checkbox" id = "checkboxSignup" required>
          I have read and accept the <a class = "c-alpha-signup__link" @click="openTermsModal()" target = "_blank">Terms of Service & Data Privacy Policy</a>
          <span class="c-alpha-signup__checkmark"></span>
        </label>
      </div>
      <button class = "c-alpha-signup__button" :class="{'c-alpha-signup__button_disabled': !validateForm}" @click = "executeGeetest">Sign Up</button>
    </div>
    <p class = "c-alpha-signup__signin-bottom">
      Already have an account? <a @click='routerRedirect("signin")' class = "c-alpha-signup__signin-bottom-link">Sign In</a>
    </p>

    <Windsor></Windsor>
    <Geetest ref="geetest" @verify="register"></Geetest>
    <InvalidCaptchaAlert v-if = "invalidCaptchaStatus"></InvalidCaptchaAlert>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce';
import InvalidCaptchaAlert from '@/components/InvalidCaptchaAlert'
import Geetest from '@/components/Geetest'
import publicKey from 'raw-loader!../../public/key/sparrowSecurityAuthPub.key'
import Windsor from '@/components/Windsor.vue'
export default {
  components: {
    Geetest,
    InvalidCaptchaAlert,
    Windsor
  },
  data() {
    return {
      input:{
        email: '',
        password: '',
        t:Date.now(),
        product: {
          'OPTIONS': {
            'isActive': false
          }
        }
      },
      forge: require('node-forge'),
      processing: false,
      maintenance:false,
      validPassword:true,
      validEmail:true,
      isCnfChecked: true,
      isOptChecked: false,
      isChecked: false,
      publicKey:publicKey,
      pubKey:null,
      captchaRes:'',
      invalidCaptchaStatus: false,
      showPassword:false,
      errorMessage:''
    }
  },
  created() {
    this.$eventHub.$on('invalidCaptchaStatus', (status) =>{
      this.invalidCaptchaStatus = status
    });
    this.$eventHub.$on('reject-terms', () =>{
      this.isChecked = false
    });
  },
  watch: {
    isChecked(dt) {
      if (dt) {
        this.$eventHub.$emit('show-terms')
      }
    },
    isOptChecked(dt) {
      this.input.product.OPTIONS.isActive = dt
    }
  },
  mounted(){
    this.pubKey = this.forge.pki.publicKeyFromPem(this.publicKey);
  },
  methods:{
    openTermsModal(){
      //alert();
    },
    checkMaintenance() {
      this.$http.get(this.createApiUrlPricing('get_platform_status')).then(response => {
        var apistatus = response.body.api_status
        var pricingstatus = response.body.pricing_status
        if(apistatus=="0" || pricingstatus=="0") {
          this.maintenance = true
          this.$router.push('/maintenance').catch(() => {})
        } else {
          this.maintenance = false
        }
      }, err => {
        console.log(err)
        // this.showMessageError(err, false , err.status)
      })
    },
    encryptInput:function(){
      this.input.t = Date.now();
      const enc = this.symEncrypt(this.input);
      const key = this.pubKey.encrypt(enc.key, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      const iv = this.pubKey.encrypt(enc.iv, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      return {
        ciphertext: this.forge.util.encode64(enc.ciphertext),
        key: this.forge.util.encode64(key),
        iv: this.forge.util.encode64(iv),
      };
    },
    symEncrypt:function(dt){
      const key = this.forge.random.getBytesSync(32);
      const iv = this.forge.random.getBytesSync(16);
      const encCipher = this.forge.aes.createEncryptionCipher(key, 'CBC');
      encCipher.start(iv);
      encCipher.update(this.forge.util.createBuffer(JSON.stringify(dt), 'utf-8'));
      encCipher.finish();
      const ciphertext = encCipher.output.data;
      return {
        key,
        iv,
        ciphertext,
      };
    },
    executeGeetest:function(){
      if(this.processing){
        return false
      }
      this.processing = true;
      if (this.$route.name == 'Signup') {
        this.$refs.geetest.execute()
      } else {
        this.register()
      }
    },
    register: function(captchaRes){
      var encryptedInput = this.encryptInput();
      this.captchaRes = captchaRes
      let apiResp;
      if (this.$route.name == 'Signup') {
        apiResp = "users/register?geetest_challenge="+captchaRes.geetest_challenge+"&geetest_seccode="+captchaRes.geetest_seccode+"&geetest_validate="+captchaRes.geetest_validate
      } else {
        apiResp = "users/register?geetest_bypass=true"
      }
      this.$http.post(this.createApiUrlChestNut(apiResp), encryptedInput).then(() =>
      {
        this.$router.push("emailsent-verification").catch(() => {});
        this.processing = false;
      },err => {
        this.checkMaintenance()
        if(this.maintenance) {
          return false
        } else {
          if(this.input.email == '') {
            this.validEmail = false
          }
          if(this.input.password == '') {
            this.validPassword = false
          }
          this.errorMessage = err.body.message
          this.processing = false;
        }
      });
    },
    togglePassword:function(){
      this.showPassword = !this.showPassword
    },
    checkPasswordValidity: function(){
      let upperCase= new RegExp('[A-Z]');
      let numbers = new RegExp('[0-9]');
      let lowerCase = new RegExp('[a-z]');
      let specialChar = new RegExp('[~`!@#$%^&*()+=_{}\\[\\]\\-]');
      var password_length_min = 8;
      this.validPassword = this.input.password!='' && this.input.password.match(upperCase) && this.input.password.match(numbers) && this.input.password.match(lowerCase) && this.input.password.match(specialChar) && this.input.password.length >= password_length_min;
    },
    checkEmailValidity: function(){
      var emailReg = /^([\w-.+0-9]+@([\w-]+.)+[\w-]{2,4})?$/;
      this.validEmail = emailReg.test(this.input.email);
    }
  },
  computed:{
    validateForm:function(){
      return this.validEmail && this.validPassword && this.isChecked;
    },
    debouncedPasswordCheck: function(){
      return _debounce(this.checkPasswordValidity, 500);
    },
    debouncedEmailCheck: function(){
      return _debounce(this.checkEmailValidity, 500);
    }
  }
}
</script>
